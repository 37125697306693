import { useQuery } from '@tanstack/react-query';

import * as ApiPromo from '@Api/Promo';
import { useDevPanel } from '@Stores/DevPanel';
import useDeps from '@Contexts/DI/useDeps';
import useRequest from '@Hooks/useRequest';

import type { UseQueryResult } from '@tanstack/react-query';
import type { CategoryBannerData, Promotion } from '@Promo/typings';

export interface Params {
  slug: string;
}

function useCategoryPromotion(params: Params): UseQueryResult<Promotion<CategoryBannerData>> {
  const { logger } = useDeps();
  const { slug } = params;
  const { language, region, country } = useRequest();
  const { currentDate } = useDevPanel();
  const timestamp = currentDate.getTime();

  const keys = [
    'category-promotion',
    { slug, country, lang: language.id, region, currentDate: timestamp },
  ];

  const result = useQuery({
    queryKey: keys,
    queryFn: async () => {
      try {
        const inputParams = {
          slug,
          country,
          lang: language.id,
          region,
          currentDate: timestamp,
        };
        const promotion = await ApiPromo.getByCategory(inputParams);

        return promotion;
      } catch (err) {
        // Этот запрос мы считаем не критичным, если произошла ошибка,
        // то допустимо отдать пустой массив данных, чтобы сохранить доступность страницы
        logger.log(err);
        return Promise.resolve(null);
      }
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  return result;
}

export default useCategoryPromotion;
