import { memo } from 'react';
import { Helmet } from 'react-helmet-async';

import useGlobalConfig from '@Hooks/useGlobalConfig';

const FontAnton = () => {
  const { сdnUrl } = useGlobalConfig();

  return (
    <Helmet>
      <link rel='stylesheet' href={`${сdnUrl}/public/fonts/anton/font.v1.css`} />
    </Helmet>
  );
};

export default memo(FontAnton);
